<template>
  <v-select
    v-bind="$attrs"
    :items="items"
    item-text="id"
    item-value="id"
    v-on="$listeners"
  >
    <template #item="{item}">
      <div
        class="select-color-item"
        :style="{ 'background-color': item.color }"
      >
        <span class="select-color-item__text text-capitalize white--text">
          {{ item.id }}
        </span>
      </div>
    </template>
    <template #selection="{item}">
      <div
        class="select-color-item select-color-item--selection"
        :style="{ 'background-color': item.color }"
      >
        <span class="select-color-item__text text-capitalize white--text">
          {{ item.id }}
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { colors } from '@/compositions/map/utils/data';
import { computed } from 'vue-demi';
export default {
  name: 'FormColorSelector',
  setup() {
    return {
      items: computed(() =>
        Object.keys(colors).map(key => ({ id: key, color: colors[key] }))
      )
    };
  }
};
</script>

<style lang="sass" scoped>
.select-color-item
    width: 100%
    height: 100%
    display: flex
    padding: 6px
    &__text
        margin: auto 0
    &--selection
        padding: 3px
        padding-left: 6px
        height: 24px
</style>
